import "./PublicSiteHome.css";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import TeamCard from "../components/TeamCard";
import heroImg from "../../assets/xpressSelectHeaderImage.webp";
import teamImg1 from "../../assets/team_hasti.jpg";
import teamImg2 from "../../assets/team_hana.jpg";
import teamImg3 from "../../assets/team_charity.jpg";
import teamImg4 from "../../assets/team_linh.jpg";
import teamImg5 from "../../assets/team_josh.jpg";
import teamImg6 from "../../assets/team_cole.png";

function AboutUs() {
  return (
    <div className="frontPage">
      <NavBar currPage="/aboutus"></NavBar>

      <div className="AboutUsHeroContainer">
        <div className="AboutUsHeroImgContainer">
          <img className="AboutUsHeroImg" src={heroImg} alt="XpressSelect presentation by Hana and Hasti"></img>
        </div>

        <div className="ContactUsContainer">
          <h2>We believe in empowering pharmacies to enhance patient care with innovative technology.</h2>
          <p>
            Xpress Select, led by UBC Doctor of Pharmacy students and supported by experts in pharmacy, technology, and business, is focused on improving the pharmacy experience. By combining clinical
            insight with innovative technology, we aim to make healthcare solutions more accessible and efficient.
          </p>
        </div>
      </div>

      <div className="AboutTeamContainer">
        <h1>The team behind our vision</h1>
        <div className="TeamCardsContainer">
          <TeamCard src={teamImg1} fullname="Hasti Haghdadi" position="Co-Founder & COO" credentials={["Entry-to-Practice Doctor of Pharmacy Candidate", "UBC Class of 2026"]} />
          <TeamCard src={teamImg2} fullname="Hana Rahili" position="Co-Founder & CEO" credentials={["Entry-to-Practice Doctor of Pharmacy Candidate", "UBC Class of 2025"]} />
          <TeamCard src={teamImg3} fullname="Charity Grey" position="Tech Lead" credentials={["B.Sc Combined Major in Computer Science and Biology", "UBC Class of 2026"]} />
          <TeamCard src={teamImg4} fullname="Linh Le" position="Business Advisor" credentials={["UBC Pharmaceutical Sciences", "Entrepreneur in Residence (EiR)", "Human Health Venture Studio"]} />
          <TeamCard src={teamImg5} fullname="Josh Grift" position="Technical Advisor" credentials={["Mentor at e@UBC", "Software engineer"]} />
          <TeamCard src={teamImg6} fullname="Cole Bailey" position="Legal Advisor" credentials={["Business Lawyer at Coal Harbour Law"]} />
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default AboutUs;
