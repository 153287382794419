import { Link } from "react-router-dom";
import Footer from "../components/Footer";
import NavBar from "../components/NavBar";
import "./PublicSiteHome.css";
import "./HeaderFooter.css";
import heroShader from "../../assets/heroshader.webp";
import kp_logo from "../../assets/KerrisdalePharmacy-2023.webp";
import tpk_logo from "../../assets/The-Pharmacy-Kitsilano-768x224.jpg";
import gallery1 from "../../assets/gallery1.jpg";
import gallery2 from "../../assets/gallery2.jpg";
import gallery3 from "../../assets/gallery3.jpg";
import gallery4 from "../../assets/gallery4.jpg";
import gallery5 from "../../assets/gallery5.jpg";

const images = [gallery1, gallery2, gallery3, gallery4, gallery5];

function PublicSiteHome() {
  return (
    <div className="frontPage">
      <NavBar currPage="/"></NavBar>

      <div id="publicSiteBody">
        <div className="heroShader">
          <img  src={heroShader} alt='Decorative gradient'></img>
        </div>
        <div className="heroText">
          <h1>Timely, accurate recommendations</h1>
          <p>
            Our digital platform streamlines over-the-counter recommendations and 
            minor ailment prescription services, saving pharmacists time and enhancing the patient experience.
          </p>
          <div>
            <Link to="/contactus" className="focusButton">
              Contact Us
            </Link>
          </div>
        </div>
      </div>

      <div className="partners">
        <h1>Our Partners</h1>
        <div className="PartnersTextContainer">
          <p>
            We&apos;re pleased to announce that we are currently piloting our
            product with Kerrisdale Pharmacy and The Pharmacy Kitsilano since
            November 2024.
          </p>
          <div className="PartnersLogoContainer">
            <img
              className="pharm-logo"
              src={kp_logo}
              alt=" Kerrisdale Pharmacy logo"
            ></img>
            <img
              className="pharm-logo"
              src={tpk_logo}
              alt="The Pharmacy Kitsilano"
            ></img>
          </div>
        </div>
        <Gallery></Gallery>
      </div>

      <Footer />
    </div>
  );
}

function Gallery() {
  return (
    <div className="gallery-container">
    <div className="gallery">
      {images.map((image, index) => (
        <div className="gallery-item" key={index}>
          {" "}
          <img src={image} alt={`Gallery item ${index}`} />{" "}
        </div>
      ))}
    </div>
  </div>
  );
}

export default PublicSiteHome;
