import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { useState } from "react";
import authCodes from "./authCodes.json";
import { usePostHog } from "posthog-js/react";
// ================ pages ================ //
import AuthPage from "./pages/AuthPage";
import Home from "./pages/Home";
import QuestionairePage from "./pages/QuestionairePage";
import WaiverPage from "./pages/WaiverPage";
import Map from "./pages/Map";
import FinishPage from "./pages/FinishPage";
import PublicSiteHome from "./business_site/pages/PublicSiteHome";
import AboutUs from "./business_site/pages/AboutUs";
import ContactUs from "./business_site/pages/ContactUs";
import Services from "./business_site/pages/Services";

/*
localStorage verifiedAs is one of = 
        {"Glen Pharmacy", "Kerrisdale Pharmacy", "Simples Drugs",
          0 = unauth, "Developer-Charity", "Tester-Hana", "Tester-Hasti"}
*/

export function getEmailTemplateId() {
  return authCodes[localStorage.getItem('verifiedAs')]['email'];
}

// props = {emailJsOptions}
function App(props) {
  var curTime = new Date();
  if (curTime > new Date("Dec 31 2025")) {
    localStorage.removeItem("verifiedAs");
  }
  const posthog = usePostHog();
  const [verifiedAs, setVerification] = useState(
    localStorage.getItem("verifiedAs") || 0
  );
  const verify = (formValue) => {
    const code = formValue["formValue"].toLowerCase();
    if (authCodes[code] && authCodes[code]["valid"]) {
      if (code === "developer-charity") {
        console.log("set Local Storage as authenticated");
      } else if (code === "developer-charity-unverify") {
        localStorage.removeItem("verifiedAs");
        return;
      }
      localStorage.setItem("verifiedAs", code);

      const userName = authCodes[code]["name"];
      if (
        userName === "the pharmacy kitsilano" ||
        userName === "kerrisdale pharmacy"
      ) {
        localStorage.setItem("MACS_email_count", 0);
        localStorage.setItem("maximum_MACS_email_count", 80);
      } else {
        localStorage.setItem("MACS_email_count", 0);
        localStorage.setItem("maximum_MACS_email_count", 0);
      }
      setVerification(code);
    }
  };

  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element=<PublicSiteHome /> />
          <Route path="/aboutus" element=<AboutUs /> />
          <Route path="/contactus" element=<ContactUs /> />
          <Route path="/services" element=<Services /> />
          <Route path="/select" element={verifiedAs === 0 ? <AuthPage verifyFn={verify} /> : <Home />}/>
          <Route path="/waiver" element={ verifiedAs === 0 ? <AuthPage verifyFn={verify} /> : <WaiverPage /> } />
          <Route path="/questionaire"
            element={
              verifiedAs === 0 ? (
                <AuthPage verifyFn={verify} />
              ) : (
                <QuestionairePage
                  emailJs_options={props.emailJs_options}
                  posthogObj={posthog}
                />
              )
            }
          />
          <Route path="/finish/:finishId"
            element={
              verifiedAs === 0 ? (
                <AuthPage verifyFn={verify} />
              ) : (
                <FinishPage posthogObj={posthog} />
              )
            }
          />
          <Route
            path="/emergencies"
            element={
              verifiedAs === 0 ? (
                <AuthPage verifyFn={verify} />
              ) : (
                <Map features="emergencies" />
              )
            }
          />
          <Route path="/walk-in-clinics"
            element={ verifiedAs === 0 ? 
              (<AuthPage verifyFn={verify} /> ) : (<Map features="walk_in_clinic" />)
            }
          />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;