// interface TeamCard {
//   src: Image;
//   fullname: string;
//   position: string;
//   credentials: string[];
// }

export default function TeamCard(props) {
  return (
    <div className="TeamCard">
      <img src={props.src} alt={"Team member " + props.fullname}></img>
      <div>
        <h3 className="CardFullName">{props.fullname}</h3>
        <p className="CardPosition">{props.position} </p>
        {props.credentials.map((item) => (
          <p className="CardCredential">{item}</p>
        ))}
      </div>
    </div>
  );
}
