import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import heroImg from "../../assets/gallery2.jpg";
import icon1 from "../../assets/serviceicon_1.png";
import icon2 from "../../assets/serviceicon_2.png";
import icon3 from "../../assets/serviceicon_3.png";

function ServicesCard(props) {
  return (
    <div className="serviceCard">
      <img src={props.src} alt='service'/>
      <p> {props.text} </p>
    </div>)
}

function Services() {
  return (
    <div className="frontPage">
      <NavBar currPage="/services"></NavBar>

      <div className="sectionCol">
        <div className="sectionRow" style={{background: 'radial-gradient(circle, rgba(159,249,233,1) 0%, rgba(210,250,243,1) 18%, rgba(250,249,246,1) 100%)'}}>
          <div className="servicesHeroImgContainer">
            <img className="servicesHeroImg" src={heroImg} alt='Pharmacy interior with our product'></img>
          </div>
          <div className="ServicesHeroText">
            <h2> We interviewed </h2> 
            <h1>78 pharmacies</h1>
            <h2> across Greater Vancouver and </h2> 
            <h1>80%</h1>
            <h2> of them confirmed a lack of time to complete non-billable tasks like over-the-counter services.</h2>
          </div>
        </div>
      </div>

      <div className="sectionCol">
      <div className="SectionSimplifyingWorkflow">
        <h1>
          <strong> Simplifying Pharmacy Workflows </strong>
        </h1>
        <div>
          <h2>Guiding patients while saving pharmacist time </h2>
          <p>Xpress Select is a digital platform designed to ease pharmacy operations by automating over-the-counter (OTC) medication recommendations and minor ailment prescription services.</p>
        </div>
        <div>
          <h2> How It Works </h2>
          <p>
          Patients answer a series of tailored questions about their symptoms and medical history. The platform then identifies the most suitable action, ensuring:
          </p>
          <div className="servicesCards" >
          <ServicesCard text="Reduced wait times" src={icon1}/>
          <ServicesCard text="Accurate advice for patients" src={icon2}/>
          <ServicesCard text="Less workload on pharmacists" src={icon3}/>
          </div>
        </div>
        <div>
          <h2>Impact</h2>
          <p>By streamlining these processes, pharmacy teams can focus on critical tasks while improving service efficiency.</p>
        </div>
      </div>
      </div>


      <Footer />
    </div>
  );
}

export default Services;
