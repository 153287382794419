import { Link } from "react-router-dom";
import headerLogo from "../../assets/transparent512x512.png";

// props = {currPage: string of relative path}
function NavBar(props) {
  const tabsInfo = [
    {to: "/aboutus", text:"About"},
    {to: "/contactus", text:"Contact"},
    {to: "/services", text:"Services"}
  ];

  const frontSiteTabs = tabsInfo.map(
    (item, i) => {
    return (<Link
      key={i}
      to={item.to} 
      className="headerTabButton"> {item.text}
     </Link>)
    });
  
  return (
    <nav>
      <div className="header">
        <div className="headerItem">
        <Link to='/' style={{ textDecoration: 'none', flexDirection: "row" }}>
          <div className="headerLogoContainer">
            <img
              className="headerLogo"
              src={headerLogo}
              alt="Xpress Select logo"
            />
            <h1>Xpress Select</h1>
          </div>
          </Link>
        </div>
        <div className="headerItem">
          <div className="headerCollapsable">
            {frontSiteTabs}
          </div>
          <Link to="/select" className="focusButton">
            Pharmacy Access
          </Link>
        </div>
      </div>
    </nav>
  );
}

export default NavBar;
