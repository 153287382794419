import "./PublicSiteHome.css";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import Logo from "../../assets/Logo.png";
import heroImg from "../../assets/xpressselect_team_hana_hasti.jpg";

function ContactUs() {
  return (
    <div className="frontPage">
      <NavBar currPage="/contactus"></NavBar>

      <div className="AboutUsHeroContainer">
        <div className="AboutUsHeroImgContainer">
          <img className="AboutUsHeroImg" src={heroImg} alt='Investor Presentation of our business'></img>
        </div>

        <div className="ContactUsContainer">
          <h1>
            <strong> Contact Us: </strong>
          </h1>
          <p>
            We&apos;d love to hear from you! For any questions or inquiries,
            feel free to reach out 
            at <a href="mailto:founders@xpressselect.com">
              founders@xpressselect.com
              </a>
            . We&apos;ll be happy to assist you with anything you need.
          </p>
          <img
            src={Logo}
            className="ContactUsLogo"
            alt="Xpress Select Logo"
          ></img>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default ContactUs;
