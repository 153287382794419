function Footer() {
  return (
    <footer className="footer">
    <div className='footer-container'>
    <div>
    <p><a className="headerTabButton" href="/aboutus">About Us</a></p>
    <p><a className="headerTabButton" href="/contactus">Contact Us</a></p>
    <p><a className="headerTabButton" href="/services">Services</a></p>
    </div>
    <div>
      <p>Copyright Xpress Select 2024</p>  
    </div>
    </div>
    <br></br>
    
    </footer>
  )
}

export default Footer;